<template>
  <base-layout-two page-title="Edit Line Item"
    :page-default-back-link="`/users/${this.$route.params.id}/folios/${$route.params.folio_id}`">
    <v-form @submit="saveTranscation">
      <base-card title="Edit Line Item">
        <p>
          Editing is limited for auditing purposes. If you need to make
          adjustments to amounts, add a new line item instead.
        </p>

        <ion-grid>
          <ion-row>
            <ion-col>
              <!-- Description -->
              <base-input label-text="Description *">
                <v-field name="description" v-slot="{ field }" v-model="transaction.description" :rules="required">
                  <ion-input name="description" v-bind="field" placeholder="Head Massage" type="text" autocapitalize
                    autocorrect disabled></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="description" class="error-message"></v-error-message>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <!-- Amount -->
              <base-input label-text="Amount * (Positive amounts for Credits, Negative amounts for Charges)">
                <v-field name="amount" v-slot="{ field }" v-model="transaction.amount" :rules="required">
                  <ion-input name="amount" v-bind="field" placeholder="0.00" type="number" disabled></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="amount" class="error-message"></v-error-message>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <!-- Amount -->
              <base-input label-text="Date *">
                <v-field name="date" v-slot="{ field }" v-model="transaction.date" :rules="required">
                  <ion-input name="date" v-bind="field" placeholder="" type="date" disabled></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="date" class="error-message"></v-error-message>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <!-- Memo -->
              <base-input label-text="Memo">
                <v-field name="memo" v-slot="{ field }" v-model="transaction.memo">
                  <ion-textarea name="memo" :auto-grow="true" rows="1" v-bind="field"
                    placeholder="Guest was given this because..." autocapitalize autocorrect></ion-textarea>
                </v-field>
              </base-input>
              <v-error-message name="memo" class="error-message"></v-error-message>
            </ion-col>
          </ion-row>
        </ion-grid>
      </base-card>

      <!-- Save Button -->
      <ion-button type="submit" expand="block" class="margin-x-8 margin-y-16">Save</ion-button>
    </v-form>
    
    <!-- Delete Button -->
    <ion-button @click="deleteTransaction" expand="block" color="danger" class="margin-x-8 margin-y-16"
      :disabled="!userCanDeleteTransaction">Delete</ion-button>
  </base-layout-two>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonButton,
  IonTextarea,
  alertController
} from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapGetters, mapActions } from "vuex";
import { format } from "date-fns";
import { userHasRole } from "@/util/helpers";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    IonTextarea,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      transaction: {
        amount: 0,
      },
      required: yup.string().required(),
    };
  },

  computed: {
    ...mapGetters(["authUser"]),

    /**
     * Users can only delete transactions that are line_items and if they the transaction_delete role
     */
    userCanDeleteTransaction() {
      if (this.transaction.type === 'line_item') {
        return userHasRole(this.authUser, "transaction_delete") ? true : false
      }
      return false
    }
  },

  async ionViewDidEnter() {
    this.fetchTransaction();
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    /**
     * Fetch Transcation
     */
    async fetchTransaction() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/transactions/${this.$route.params.transaction_id}`)
        .then((response) => {
          this.transaction = response.data.transaction;

          this.transaction.date = format(new Date(response.data.transaction.date), "yyyy-MM-dd");
        })
        .catch((error) => { console.log(error) });
    },


    /**
     * Save Transaction
     */
    async saveTranscation() {
      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/transactions/${this.$route.params.transaction_id}`,
        data: this.transaction,
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      // Fix Date format
      if (config.data.date)
        config.data.date = new Date(config.data.date)
          .toISOString()
          .replace("T", " ")
          .replace("Z", "");

      await this.axios(config)
        .then((response) => {
          this.transaction = response.data.transaction;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Line Item Updated", color: "secondary" });
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },


    /**
     * Delete Transcation
     * 
     * First show an alert and then delete on confirmation
     */
    async deleteTransaction() {
      const alert = await alertController.create({
        header: 'Are you sure?',
        message: 'This can not be undone.',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Yes, Delete',
            role: 'confirm',
            handler: async () => {
              const config = {
                method: "delete",
                url: `${process.env.VUE_APP_API}/transactions/${this.transaction.id}`,
              };

              await this.axios(config)
                .then((response) => {
                  if (response.data.error)
                    this.loadToast({ message: response.data.error, color: "danger" });

                  else
                    this.$router.replace(`/users/${this.$route.params.id}/folios/${this.$route.params.folio_id}`);
                })
                .catch((error) => {
                  // Turn off the network loading indicator and prompt user with a toast
                  this.setShowLoading(false);
                  this.loadToast({ message: `Something went wrong - ${error}`, color: "danger" });
                });
            },
          },
        ],
      })

      await alert.present();
    }
  },
};
</script>